import { ColumnBodyOptions } from 'primereact/column';

import NotAvailable from 'components/NotAvailable';

import { notNil } from 'helpers/Utils/misc';

import type F from 'types/generic-type';

export const SingleLineUnknown = (data: F<string>, config: ColumnBodyOptions): JSX.Element => {

  const { field } = config;

  return <div>
    { notNil(data[field]) && data[field].trim() !== ''
      ? <span>{data[field]}</span>
      : <NotAvailable label='Unknown' />
    }
  </div>;
};