import { ProfileResult } from 'components/OBXUser/Model/ProfileResult';
import { ShareEntity } from 'components/Worksheets/Models/WorksheetResponse';

export const getClddSharingItems = (
  items: ShareEntity[]
): ShareEntity[] =>
  items.filter(({ key }) => key.toLowerCase() === 'cldd');

export const findCldd = (
  items: ShareEntity[],
  activeCldd: string
): boolean =>
  Boolean(items?.find(({ value }) => value === activeCldd));

export const getUsersSharingItems = (items: ShareEntity[]): ShareEntity[] =>
  items.filter(({ key }) => key.toLowerCase() === 'user');

export const getCompanySharingItems = (items: ShareEntity[]): ShareEntity[] =>
  items.filter(({ key }) => key.toLowerCase() === 'company');

/*
Private - only owner can see WS
SharedWithCompany -  isPrivate = false and sharedWith is empty or sharedWith contains item with a company
SharedWithCldd -  isPrivate = false and sharedWith contains item with a cldd
SharedWithUser - isPrivate = false and sharedWith contains item with a user
 */
export const isWorksheetSharedWithUser = (sharedWith: ShareEntity[], activeCldd: string, user: ProfileResult): boolean => {
  const groupShared = getClddSharingItems(sharedWith);
  const usersShared = getUsersSharingItems(sharedWith);
  const companyShared = getCompanySharingItems(sharedWith);
  const isWSSharedWithCompany = Boolean(sharedWith.length === 0 || companyShared.length);
  const isWSSharedByCldd = findCldd(groupShared, activeCldd);
  const isWSSharedByUser = Boolean(
    user && usersShared?.find(userShared => userShared.entityId === user.userId)
  );

  return isWSSharedWithCompany || isWSSharedByCldd || isWSSharedByUser;
};
