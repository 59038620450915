import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'primereact/button';

import BorealisBar from 'components/BorealisBar';
import NotePanel, { NotePanelType } from 'components/NotePanel/NotePanel';
import UserRecord from 'components/OBXUser/Components/UserRecord/UserRecord';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { UserEntity } from 'components/OBXUser/Model/ProfileResult';
import {
  useLoadUserSettings,
  useValidateEmails,
} from 'components/OBXUser/Services/ProfileHooks';
import type { WorksheetResponse } from 'components/Worksheets/Models/WorksheetResponse';

import GroupShareArea from './GroupShareArea';
import { AccessLevel, ShareRequestParams, UserShareAccess } from './Models';
import UsersShareArea from './UsersShareArea';

import {
  getClddSharingItems,
  getCompanySharingItems,
  getUsersSharingItems,
} from 'helpers/Share/FilteringFunctions';

import styles from './SharePopup.module.scss';

export interface SharePopupReferenceProps {
  params: ShareRequestParams;
  externalUsers: UserEntity[];
}
interface SharePopupProps {
  worksheet: WorksheetResponse;
}

const SharePopup = (
  { worksheet: { sharedWith, isPrivate } }: SharePopupProps,
  ref: ForwardedRef<SharePopupReferenceProps>
): JSX.Element => {
  const [searchParams] = useSearchParams();
  const emails = searchParams.get('users');

  const [groupAccessLevel, setGroupAccessLevel] = useState<AccessLevel>(
    AccessLevel.None
  );
  const [clddAccessLevel, setClddAccessLevel] = useState<AccessLevel>(
    AccessLevel.None
  );
  const [clddValues, setClddValues] = useState<string[]>([]);
  const [usersEmails, setUsersEmails] = useState<string[]>([]);
  const [users, setUsers] = useState<UserShareAccess[]>([]);

  const { data: validUsers, isLoading } = useValidateEmails(
    usersEmails.length > 0 ? usersEmails : null
  );
  const { data: externalValidUsers, isLoading: isLoadingExternal } =
    useValidateEmails(emails ? [emails] : null);

  const { getSetting } = useLoadUserSettings();
  const activeCldd = getSetting(UISettings.ACTIVE_CLDD);

  useEffect(() => {
    setUsers([
      ...(validUsers?.validEmailsDetails?.map(user => ({
        ...user,
        access: AccessLevel.Edit,
      })) || []),
      ...(externalValidUsers?.validEmailsDetails?.map(user => ({
        ...user,
        access: AccessLevel.Edit,
        isExternal: true,
      })) || []),
    ]);
  }, [validUsers, externalValidUsers]);

  useImperativeHandle(
    ref,
    () => {
      const shareWithUsers = users.filter(
        ({ access }) => access === AccessLevel.Edit
      );
      return {
        params: {
          sharedWithinCompany: AccessLevel.Edit === groupAccessLevel,
          shareWithCldds:
            clddAccessLevel === AccessLevel.None ? [] : clddValues,
          shareWithUsers: shareWithUsers.map(user => user.email),
          isPrivate:
            groupAccessLevel === AccessLevel.None &&
            shareWithUsers.length === 0,
        },
        externalUsers: users.filter(user => user.isExternal),
      };
    },
    [clddAccessLevel, clddValues, groupAccessLevel, users]
  );

  /*
  Private - only owner can see WS
  SharedWithCompany -  isPrivate = false and sharedWith is empty or sharedWith contains item with a company
  SharedWithCldd -  isPrivate = false and sharedWith contains item with a cldd
  SharedWithUser - isPrivate = false and sharedWith contains item with a user
 */
  useEffect(() => {
    const sharedWithArr = sharedWith || [];
    const clddsItems = getClddSharingItems(sharedWithArr);
    const clddsGranted: string[] = clddsItems.map(
      ({ value }) => value
    );
    const usersGranted: string[] = getUsersSharingItems(
      sharedWithArr
    ).map(({ value }) => value);
    const companyGranted: string[] = getCompanySharingItems(
      sharedWithArr
    ).map(({ value }) => value);

    setClddValues(clddsGranted);
    setClddAccessLevel(
      !isPrivate && clddsGranted.length > 0
        ? AccessLevel.Edit
        : AccessLevel.None
    );
    setGroupAccessLevel(
      !isPrivate && (sharedWithArr.length === 0 || companyGranted.length)
        ? AccessLevel.Edit
        : AccessLevel.None
    );
    setUsersEmails(usersGranted);
  }, [activeCldd, isPrivate, sharedWith]);

  const onRemoveExternalUser = (user: UserShareAccess): void =>
    setUsers(users => users.filter(usr => usr !== user));

  return (
    <div className={styles.sharePopupWindow}>
      {isLoading || isLoadingExternal ? (
        <BorealisBar styleOverrides={styles.sharePopupWindowLoaderBar} />
      ) : (
        <>
          <GroupShareArea
            groupAccessLevel={groupAccessLevel}
            groupAccessLevelChanged={setGroupAccessLevel}
            clddAccessLevel={clddAccessLevel}
            setClddAccessLevel={setClddAccessLevel}
            clddValues={clddValues}
            setClddValues={setClddValues}
          />
          <div className={styles.usersSection}>
            <div className={styles.usersSectionTitle}>Shared with</div>
            {users
              .filter(usr => usr.isExternal)
              .map(extUser => (
                <NotePanel
                  key={extUser.id}
                  className={styles.sharePopupWindowInfoPanel}
                  type={NotePanelType.Info}
                  header='You are about to share this worksheet with:'
                  body={
                    <UserRecord name={extUser.name} email={extUser.email}>
                      <Button
                        icon='iconoir-trash icon--small'
                        className='p-button-text'
                        size='small'
                        onClick={(): void => onRemoveExternalUser(extUser)}
                      />
                    </UserRecord>
                  }
                />
              ))}
            <UsersShareArea users={users} usersListUpdated={setUsers} />
          </div>
        </>
      )}
    </div>
  );
};

export default forwardRef<SharePopupReferenceProps, SharePopupProps>(
  SharePopup
);
