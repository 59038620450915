export enum DLMessagesStatus {
  null = 'null',
  processed = 'processed',
  dropped = 'dropped',
  deferred = 'deferred',
  bounce = 'bounce',
  delivered = 'delivered',
  open = 'open',
}

export enum DistListModeEnum {
  dl = 1,
  single
}

export const DL_MESSAGES_STATUS_LABEL: { [key in DLMessagesStatus]: string }  = {
  [DLMessagesStatus.null]: 'Unknown',
  [DLMessagesStatus.processed]: 'Sending',
  [DLMessagesStatus.dropped]: 'Dropped',
  [DLMessagesStatus.deferred]: 'Sending',
  [DLMessagesStatus.bounce]: 'Bounced',
  [DLMessagesStatus.delivered]: 'Delivered',
  [DLMessagesStatus.open]: 'Opened',
};