import { ReactElement } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import Autocomplete from 'components/Autocomplete';
import DateTimeRange from 'components/DateTimeRange';

import './SingleSearch.scss';

interface SingleSearchMobileProps {
}

//TODO: Just a placeholder, will be updated in following tasks
const SingleSearchMobile = (props: SingleSearchMobileProps): ReactElement => {
  //const {  } = props;

  return (
    <Accordion className='single-search-mobile__accordion'>
      <AccordionTab className='single-search-mobile__accordion-tab'
        headerClassName='single-search-mobile__accordion-header'
        header='Search'>
        <div className='single-search-mobile__container'>
          <Autocomplete
            handleInputValueChange={() => {}}
            handleSelectedItemsChange={() => {}}
            selectedItems={[]}
            suggestions={[]}
            placeholder='Search by attachment, message body, recipient, subject'
          />
          <DateTimeRange
            placeholder='Date, time'
            defaultValue={''}
            showErrorMessage={false}
          />
          <Button
            text
            size='small'
            disabled={true}
            onClick={() => {}}
          >
            Clear
          </Button>
        </div>
      </AccordionTab>
    </Accordion>);
};

export default SingleSearchMobile;