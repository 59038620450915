import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSignalR } from 'App';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';

import Loader from 'components/Loader';
import SecondaryNavigation from 'components/SecondaryNavigation';
import ToastMessage, {
  ToastMessageRef, ToastSeverity,
} from 'components/ToastMessage';

import { deferNextAction } from '../CargoTracker/Components/CargoEditWarningDialog';

import { DistListWarningDialogEvents } from './Components/CloseWarningDialog';
import List from './Components/List';
import SidePanel from './Components/SidePanel';
import Single from './Components/Single';
import SingleSearch from './Components/SingleSearch';
import NewSingleMessageButton from './Components/SingleSearch/NewSingleMessageButton';
import SingleSearchMobile from './Components/SingleSearch/SingleSearchMobile';
import { DistributionList } from './Models/distribution-list-response';
import { DistListModeEnum } from './Models/Enums';
import { useGetDistLists } from './Services/DistListService';
import { DistListSignalEventTypes, DistributionListSocket } from './Services/SignalRSocket';

import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';
import { stripParams } from 'helpers/Utils/string';

import eventBus from 'server/EventBus';

import type { DistListConfig } from 'index';

import './DistListPage.scss';

interface DistListProps {
  items: DistListConfig[];
  resultsMode: DistListModeEnum;
}

const DistListPage = (props: DistListProps): ReactElement => {
  const { items, resultsMode } = props;
  const { loadDistlistData, loadDistlistError, loadDistlistIsLoading } = useGetDistLists();
  const toast = useRef<ToastMessageRef>(null);
  const [ distLists, setDistLists] = useState<DistributionList[] | undefined | void>(undefined);
  const [ isRightColumnVisible, setIsRightColumnVisible] = useState<boolean>(false);
  const [ selectedDL, setSelectedDL] = useState<DistributionList | null>(null);
  const [ isLoading, setIsLoading] = useState<boolean>(false);
  const [ activeDetailTab, setActiveDetailTab ] = useState<number>(0);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { signal } = useSignalR();
  
  const navigate: NavigateFunction = useNavigate();
  const currentRoute = useLocation();
  const { distListIdParam, emailIdParam } = useParams();

  useEffect(() => {
    setDistLists(loadDistlistData);
  }, [loadDistlistData]);

  useEffect(() => {
    if (distListIdParam && !loadDistlistIsLoading && distLists !== undefined) {
      const foundDistList = distLists.find(list => list.id === distListIdParam);
      if (foundDistList) {
        setSelectedDL(foundDistList);
        setIsRightColumnVisible(true);
      } else {
        toast.current?.replace({
          title: 'Wrong Distribution List ID',
          message: 'Provided Distribution List ID in link was not found',
          severity: ToastSeverity.WARN
        });
      }
    }
  }, [distListIdParam, distLists, loadDistlistIsLoading]);

  // On resultMode change, hide right panel
  useEffect(() => {
    setSelectedDL(null);
    setIsRightColumnVisible(false);
  }, [resultsMode]);

  const addDLButton = useMemo(() => {
    const handleAddDL = (): void => {
      setActiveDetailTab(1);
      setSelectedDL(null);
      setIsRightColumnVisible(true);
    };

    return (
      <Button
        size="small"
        className="distlist-add no-background"
        onClick={async ():Promise<void> => {
          const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
            null,
            () => handleAddDL());
          if (result) {
            return;
          }

          handleAddDL();
        }}
      >
        Add distribution list
      </Button>);
  }, []);

  const handleSelection = (value?: DistributionList):void => {
    if (value?.id) {
      const route = stripRoute();
      navigate(`${ route }${ value.id }`);
      setSelectedDL(value);
      setIsRightColumnVisible(true);
    } else {
      handleCloseSidePanel();
    }
  };

  const handleCloseSidePanel = ():void => {
    const route = stripRoute();
    navigate(route);
    setSelectedDL(null);
    setIsRightColumnVisible(false);
  };

  const stripRoute = ():string => {
    let route = stripParams(currentRoute.pathname, distListIdParam); // // Strip DistListID, selectedDL?.id
    route = stripParams(route, emailIdParam); // Strip also email ID
    return route;
  };

  const handleSelectionChange = async (e: DataTableSelectionSingleChangeEvent<DataTableValueArray>): Promise<void> => {
    const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
      null,
      () => handleSelection(e.value as DistributionList));
    if (result) {
      return;
    }

    handleSelection(e.value as DistributionList);
  };

  useEffect(() => {
    setIsLoading(loadDistlistIsLoading || loadDistlistError !== undefined);
  }, [loadDistlistIsLoading, loadDistlistError]);

  useEffect(() => {
    const socket: DistributionListSocket = DistributionListSocket.instance;
    socket.init(signal);
  }, [signal]);

  const handleUpdateData = useCallback((event: CustomEvent<DistributionList>): void => {
    if (distLists === undefined) {
      return;
    }

    const index: number = distLists.findIndex(d => d.id === event.detail.id);
    const parsedData = parsePropsToDateTime(event.detail, ['lastEmailDate']);
    const newData = (index !== -1 && index !== undefined) ?
      replaceItemAt(distLists, parsedData, index) : // Update existing
      [ // Add new item
        ...distLists,
        parsedData
      ];
    setDistLists(newData);
  }, [distLists]);

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);

    return ():void => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);
    };
  }, [handleUpdateData]);

  return (
    <>
      <nav className='distlist-navigation'>
        <SecondaryNavigation items={items} />
      </nav>
      {resultsMode === DistListModeEnum.dl && !isMobile &&
        <header className='align--right distlist-header'>
          {addDLButton}
        </header>}
      {resultsMode === DistListModeEnum.single  &&
        <header className='align--right distlist-header'>
          {isMobile ? <SingleSearchMobile /> : <SingleSearch />}
        </header>}
      <main
        className={clsx(
          'distlist-container',
          distLists !== undefined && distLists?.length > 0 && 'distlist-container-table',
          { 'drawer--active': isRightColumnVisible },
          'grow-to-fill')}
        {...(isRightColumnVisible && {
          'data-cols': '5,7',
          'data-drawer-style': 'slide',
          'data-drawer-position': 'alongside-right',
        })}
      >
        {isLoading && (
          <section className="distlist-empty grow-to-fill">
            <Loader className="no-background" />
          </section>
        )}
        {distLists !== undefined && distLists.length === 0 && !isLoading && (
          <section className="distlist-empty grow-to-fill">
            <i className="iconoir-folder-plus no-background"></i>
            <h2 className="no-background">
              {resultsMode === DistListModeEnum.dl ?
                'It looks like no list has been added yet' :
                'It looks like no message has been created yet'}
            </h2>
            { resultsMode === DistListModeEnum.dl ? addDLButton : <NewSingleMessageButton /> }
          </section>
        )}
        {distLists !== undefined && distLists?.length > 0 && !isLoading && (
          <section className="grow-to-fill">
            {resultsMode === DistListModeEnum.dl &&
            <List
              distLists={distLists}
              selectedDL={selectedDL}
              handleSelection={handleSelection}
              handleSelectionChange={handleSelectionChange}
            />}
            {resultsMode === DistListModeEnum.single &&
              <Single
                distLists={[]}
                selectedDL={selectedDL}
                handleSelection={handleSelection}
                handleSelectionChange={handleSelectionChange}
              />}
          </section>
        )}
        {isRightColumnVisible && resultsMode === DistListModeEnum.dl && (
          <SidePanel
            activeDL={selectedDL}
            activeDetailTab={activeDetailTab}
            setActiveDetailTab={setActiveDetailTab}
            handleClose={handleCloseSidePanel}
            toast={toast} />
        )}
      </main>
      {(isMobile && !isRightColumnVisible) &&
        <footer className="align--right distlist-footer">
          { resultsMode === DistListModeEnum.dl ? addDLButton : <NewSingleMessageButton /> }
        </footer>}
      <ToastMessage ref={toast} className="distlist-toast" />
    </>
  );
};

export default DistListPage;
