import { ReactElement } from 'react';
import { Button } from 'primereact/button';

import Autocomplete from 'components/Autocomplete';
import DateTimeRange from 'components/DateTimeRange';

import NewSingleMessageButton from './NewSingleMessageButton';

interface SingleSearchProps {
}

//TODO: Just a placeholder, will be updated in following tasks
const SingleSearch = (props: SingleSearchProps): ReactElement => {
  //const {  } = props;

  return (
    <>
      <Autocomplete
        handleInputValueChange={() => {}}
        handleSelectedItemsChange={() => {}}
        selectedItems={[]}
        suggestions={[]}
        placeholder="Search by attachment, message body, recipient, subject"
      />
      <DateTimeRange
        placeholder='Date, time'
        defaultValue={''}
        showErrorMessage={false}
      />
      <Button
        text
        size="small"
        disabled={true}
        onClick={() => {}}
      >
        Clear
      </Button>
      <NewSingleMessageButton />
    </>);
};

export default SingleSearch;