import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable, DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';

import GridElement from 'components/GridElement';

import { DistributionList } from '../../Models/distribution-list-response';

import { sortByDateTime } from 'helpers/DataTable/SortingFunctions';
import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';

import F from 'types/generic-type';

interface ListProps {
  distLists: DistributionList[];
  selectedDL: DistributionList | null;
  handleSelection: (value?: DistributionList) => void;
  handleSelectionChange: (e: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => Promise<void>;
}

const DATE_FORMAT = 'dd LLL yyyy, HH:mm ZZZZ'

const List = (props: ListProps): JSX.Element => {
  const { distLists, selectedDL, handleSelection, handleSelectionChange } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });


  const itemTemplate = (item: DistributionList): JSX.Element => (
    <div className={clsx('list-element')}
      onClick={(): void => handleSelection(item)}
      key={item.id}>
      <GridElement header="Distribution List" body={item.name} />
      <GridElement header="Recipients" body={`${ item.recipientsCount }`} />
      <GridElement header="Last Email" body={ReadableDate(item, { field: 'lastEmailDate' }, DATE_FORMAT)} />
    </div>
  );
  
  return (
    <>
      {isMobile ?
        <DataView value={distLists}
          itemTemplate={itemTemplate}
          className="distlist__view grow-to-fill no-background" /> :
        <DataTable
          className="distlist__table--distLists grow-to-fill"
          dataKey="id"
          value={distLists}
          sortField="lastEmailDate"
          sortOrder={-1}
          scrollable
          removableSort
          selectionMode="single"
          selection={selectedDL}
          onSelectionChange={handleSelectionChange}
          metaKeySelection={false}
        >
          <Column header="Distribution List" field="name" sortable />
          <Column header="Recipients" field="recipientsCount" sortable />
          <Column header="Last Email"
            field="lastEmailDate"
            body={(data, config):JSX.Element => ReadableDate<DistributionList>(data, config, DATE_FORMAT)}
            sortable
            sortFunction={(e: ColumnSortEvent):F<DateTime>[] => sortByDateTime(e, 'lastEmailDate')}
          />
        </DataTable>}
    </>);

};

export default List;