import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ToastMessageRef } from 'components/ToastMessage';

import { useGetDistListById, useSearchMessages } from '../../Services/DistListService';
import { DistListSignalEventTypes } from '../../Services/SignalRSocket';

import MessageDesktop from './MessageDesktop';
import MessageMobile from './MessageMobile';

import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import eventBus from 'server/EventBus';

import type { DistributionListMessagesSearchRequest } from '../../Models/distribution-list-create-request';
import type {
  DistributionListMessagesSearchResponse
} from '../../Models/distribution-list-response';

interface MessageProps {
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  activeDlId?: DistributionListMessagesSearchResponse['id'];
  dlName?: string;
  activeDetailTab?: number;
}

const Message = (props: MessageProps): JSX.Element => {
  const {activeDlId, handleClose, toast, dlName, activeDetailTab, setActiveDetailTab} = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [subjectSearch, setSubjectSearch] = useState<string>();
  const [messages, setMessages] = useState<DistributionListMessagesSearchResponse[] | undefined>(undefined);
  const [searchItems, setSearchItems] =
   useState<DistributionListMessagesSearchRequest>({ distributionListId: activeDlId ?? '' });

  const { searchResults, searchIsLoading } = useSearchMessages(searchItems);

  useEffect(() => {
    setSearchItems({
      distributionListId: activeDlId ?? '',
      subjectSearch: subjectSearch,
    });
  }, [activeDlId, subjectSearch, setSearchItems]);

  useEffect(() => {
    // Clear value on DL change
    setSearchValue('');
    setSubjectSearch(undefined);
  }, [activeDlId]);

  useEffect(() => {
    if (searchResults !== undefined && !searchIsLoading) {
      setMessages(searchResults);
    }
  }, [searchIsLoading, searchResults]);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const {data, isLoading} = useGetDistListById(activeDlId);

  const handleUpdateEmail = useCallback((event: CustomEvent<DistributionListMessagesSearchResponse>):void => {
    if (event.detail.distributionListId !== activeDlId) {
      return;
    }

    setMessages(msgs => {
      if (msgs) {
        const index: number = msgs.findIndex(d => d.id === event.detail.id);
        const parsedData = parsePropsToDateTime(event.detail, ['messageDate']);

        return index !== -1 ?
          // Update existing
          replaceItemAt(msgs, parsedData, index) :
          // Add new item
          [...msgs, parsedData];
      }

      return msgs;
    });
  }, [activeDlId]);

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_EMAIL_UPDATED, handleUpdateEmail);

    return () => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_EMAIL_UPDATED, handleUpdateEmail);
    };
  }, [handleUpdateEmail]);

  return isMobile ?
    <MessageMobile
      messages={messages}
      setMessages={setMessages}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSubjectSearch={setSubjectSearch}
      isLoading={isLoading || searchIsLoading}
      isCreateDisabled={!data?.recipients.length}
      handleClose={handleClose}
      activeDlId={activeDlId}
      dlName={dlName}
      activeDetailTab={activeDetailTab}
      setActiveDetailTab={setActiveDetailTab}
    /> :
    <MessageDesktop
      messages={messages}
      setMessages={setMessages}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSubjectSearch={setSubjectSearch}
      isLoading={isLoading || searchIsLoading}
      isCreateDisabled={!data?.recipients.length}
      handleClose={handleClose}
      activeDlId={activeDlId}
      toast={toast}
      setActiveDetailTab={setActiveDetailTab}
    />;
};

export default Message;
