import { ReactElement, useCallback } from 'react';
import * as React from 'react';
import { Button } from 'primereact/button';

interface NewSingleMessageButtonProps {
// TODO: Will implement in next task
}

const NewSingleMessageButton = (props: NewSingleMessageButtonProps): ReactElement => {

  const handleCNM = useCallback((): void => {
    // TODO: Will implement in next task
  }, []);

  return (
    <Button
      size="small"
      className="distlist-add no-background"
      onClick={handleCNM}
    >
      Create New Message
    </Button>);
};


export default NewSingleMessageButton;