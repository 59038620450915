import BorealisBar from 'components/BorealisBar';
import type { DistributionListMessagesSearchResponseFlat } from 'modules/DistList/Models/distribution-list-response';

interface FooterTemplateProps {
  data: DistributionListMessagesSearchResponseFlat;
  messageIdForRecipientsToLoad: string | null;
}

const FooterTemplate = (props: FooterTemplateProps): JSX.Element => {
  const { data, messageIdForRecipientsToLoad } = props;

  if (messageIdForRecipientsToLoad === data.id) {
    return <div className='position--relative'><BorealisBar /></div>;
  }

  if (!data.recipients?.length) {
    return <div className='header-footer--no-items'>No items found</div>;
  }

  return <div className='footer-sent-by'>Sent by {data.requestingUserName ?? data.requestingUserEmailAddress ?? 'Unknown'}</div>;
};

export default FooterTemplate;